import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import card from "../images/card.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { addPracticeReq, deletePracticeReq, getPracticeReq, updatePracticeReq } from "../apis";
import axios from "axios";

const Practice = () => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const navigate = useNavigate();
  const [allPractice, setAllPractice] = useState([]);
  const [editData, setEditData] = useState({
    id: "",
    title: "",
    description: "",
    subtitle: "",
    video: "",
    thumbnail: "",
  });
  const [practice, setPractice] = useState({
    title: "",
    description: "",
    subtitle: "",
    video: "",
    thumbnail: "",
  });

  const handlePracticeInput = (e) => {
    setPractice((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

    const handleEditPractice = (e) => {
      setEditData((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
    };
      const fileEditInputHandler = async (e, type) => {
        if (type === "image") {
          setImageLoading(true);
        } else {
          setVideoLoading(true);
        }
        const file = new FormData();
        file.append("file", e.target.files[0]);
        try {
          const res = await axios.post(
            "https://aws-uploader.onrender.com/api/v2/upload/file",
            file
          );
          if (res?.data?.msg === "Video Upload successfully") {
            setEditData((prev) => {
              return {
                ...prev,
                [e.target.name]: res?.data?.link,
              };
            });
            if (type === "image") {
              alert("Thumbnail Uploaded!");
            } else {
              alert("Video Uploaded!");
            }
          }
          if (type === "image") {
            setImageLoading(false);
          } else {
            setVideoLoading(false);
          }
        } catch (error) {
          if (type === "image") {
            setImageLoading(false);
          } else {
            setVideoLoading(false);
          }
          alert("Upload Failed!");
        }
      };

  const fileInputHandler = async (e, type) => {
    if (type === "image") {
      setImageLoading(true);
    } else {
      setVideoLoading(true);
    }
    const file = new FormData();
    file.append("file", e.target.files[0]);
    try {
      const res = await axios.post(
        "https://aws-uploader.onrender.com/api/v2/upload/file",
        file
      );
      if (res?.data?.msg === "Video Upload successfully") {
        setPractice((prev) => {
          return {
            ...prev,
            [e.target.name]: res?.data?.link
          }
        })
        if(type === "image") {
          alert("Thumbnail Uploaded!");
        }else {
          alert("Video Uploaded!");

        }
      }
        if (type === "image") {
          setImageLoading(false);
        } else {
          setVideoLoading(false);
        }
    } catch (error) {
      if (type === "image") {
        setImageLoading(false);
      } else {
        setVideoLoading(false);
      }
      alert("Upload Failed!")
    }
  };

  const getPracticeHandler = async () => {
    try {
      setLoading2(true);
      const res = await getPracticeReq();
      if (res?.data?.success) {
        setAllPractice(res?.data?.data);
      }
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  const deletePracticeHandler = async (id) => {
    const isAllow = window.confirm("Are you sure you want to delete?");
    if (isAllow) {
      try {
        const res = await deletePracticeReq(id);
        if (res?.data?.success) {
          alert("Deleted Successfully!");
          getPracticeHandler();
        }
      } catch (error) {
        alert("Something went wrong!");
      }
    }
  };


  const addPracticeHandler = async(e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await addPracticeReq(practice);
      console.log(res);
      alert("Added Successfully!")
      setShow(false);
      getPracticeHandler();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Something went wrong!");
    }
  }

  const updatePracticeHandler = async(e, id) => {
    e.preventDefault();
        try {
      setLoading3(true);
      const res = await updatePracticeReq(editData?.id, {
            title: editData.title,
    description: editData.description,
    subtitle: editData.subtitle,
    video: editData.video,
    thumbnail: editData.thumbnail,
      });
      console.log(res);
            alert("Update Successfully!");
            setShow2(false);
            getPracticeHandler();
      setLoading3(false);
    } catch (error) {
      setLoading3(false);
      alert("Something went wrong!")
    }
  }
  useEffect(() => {
    getPracticeHandler();
  }, []);

  return (
    <>
      <Box className="d-flex align-items-center justify-content-between mb-5">
        <div>
          <h6 className="heading1">Best Resume/Template</h6>
          <span className="heading">Best Resume/Template</span>
        </div>
        <button
          style={{
            marginLeft: "20px",
            width: "200px",
            height: "40px",
            border: "none",
            backgroundColor: "#521986",
            color: "white",
            outline: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => setShow(true)}
        >
          Add New
        </button>
      </Box>

      {loading2 ? (
        <div
          style={{
            height: "50vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" size="md" />
        </div>
      ) : (
        <Box className="d-flex flex-wrap">
          {allPractice?.map((item) => {
            return (
              <div className="practice-card-container me-3 d-flex justify-content-between">
                <div
                  className="practice-img mb-2"
                  onClick={() => navigate(`/resumatic/products/${item?._id}`)}
                >
                  <img src={item?.thumbnail} alt="thumbnail" />
                </div>

                <h3
                  onClick={() => navigate(`/resumatic/products/${item?._id}`)}
                  className="text-bold"
                >
                  {item?.title}
                </h3>

                <p
                  onClick={() => navigate(`/resumatic/products/${item?._id}`)}
                  style={{ fontSize: "14px" }}
                  title={item?.description}
                >
                  {item?.description?.slice(0, 100)}...
                </p>

                <div className="d-flex justify-content-between w-100">
                  <button
                    style={{
                      width: "150px",
                      height: "38px",
                      border: "none",
                      backgroundColor: "#521986",
                      color: "white",
                      outline: "none",
                      borderRadius: "5px",
                      marginRight: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditData({
                        id: item?._id,
                        title: item?.title,
                        description: item?.description,
                        subtitle: item?.subtitle,
                        video: item?.video,
                        thumbnail: item?.thumbnail,
                      });
                      setShow2(true);
                    }}
                  >
                    Edit
                  </button>

                  <button
                    style={{
                      width: "150px",
                      height: "38px",
                      border: "none",
                      backgroundColor: "#521986",
                      color: "white",
                      outline: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => deletePracticeHandler(item?._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        </Box>
      )}

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Add New</h3>
            <form onSubmit={addPracticeHandler} className="w-100">
              <label htmlFor="title" className="d-flex flex-column mb-3">
                <span>Title</span>
                <input
                  type="text"
                  placeholder="Enter Title"
                  className="modal-input"
                  name="title"
                  required
                  onChange={handlePracticeInput}
                />
              </label>
              <label htmlFor="subtitle" className="d-flex flex-column mb-3">
                <span>Subtitle</span>
                <input
                  type="text"
                  placeholder="Enter Subtitle"
                  className="modal-input"
                  name="subtitle"
                  required
                  onChange={handlePracticeInput}
                />
              </label>
              <label htmlFor="description" className="d-flex flex-column mb-3">
                <span>Description</span>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  style={{ height: "200px" }}
                  className="modal-input"
                  placeholder="Enter Description"
                  onChange={handlePracticeInput}
                ></textarea>
              </label>
              <span>Upload Thumbnail</span>
              <div className="d-flex align-items-center border-fileInput">
                <label htmlFor="thumbnail" className="d-flex flex-column">
                  <div className="upload-btn-wrapper w-100 h-65">
                    <button className="btn-2 d-flex align-items-center">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        Upload Thumbnail
                      </div>
                    </button>
                    <input
                      type="file"
                      name="thumbnail"
                      id="thumbnail"
                      required
                      onChange={(e) => fileInputHandler(e, "image")}
                    />
                  </div>
                </label>
                {imageLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  practice?.thumbnail && (
                    <a
                      href={practice?.thumbnail}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  )
                )}
              </div>

              <span>Upload Video</span>
              <div className="d-flex align-items-center border-fileInput">
                <label htmlFor="video" className="d-flex flex-column">
                  <div className="upload-btn-wrapper w-100 h-65">
                    <button className="btn-2 d-flex align-items-center">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        Upload Video
                      </div>
                    </button>
                    <input
                      type="file"
                      name="video"
                      id="video"
                      required
                      onChange={(e) => fileInputHandler(e, "video")}
                    />
                  </div>
                </label>
                {videoLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  practice?.video && (
                    <a href={practice?.video} target="_blank" rel="noreferrer">
                      View
                    </a>
                  )
                )}
              </div>

              <div>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "none",
                    backgroundColor: "#521986",
                    color: "white",
                    outline: "none",
                    borderRadius: "5px",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "1px solid #521986",
                    backgroundColor: "white",
                    color: "#521986",
                    outline: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={() => setShow2(false)} centered>
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Edit</h3>
            <form onSubmit={updatePracticeHandler} className="w-100">
              <label htmlFor="title" className="d-flex flex-column mb-3">
                <span>Title</span>
                <input
                  type="text"
                  placeholder="Enter Title"
                  className="modal-input"
                  name="title"
                  required
                  onChange={handleEditPractice}
                  defaultValue={editData?.title}
                />
              </label>
              <label htmlFor="subtitle" className="d-flex flex-column mb-3">
                <span>Subtitle</span>
                <input
                  type="text"
                  placeholder="Enter Subtitle"
                  className="modal-input"
                  name="subtitle"
                  required
                  onChange={handleEditPractice}
                  defaultValue={editData?.subtitle}
                />
              </label>
              <label htmlFor="description" className="d-flex flex-column mb-3">
                <span>Description</span>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  style={{ height: "200px" }}
                  className="modal-input"
                  placeholder="Enter Description"
                  onChange={handleEditPractice}
                  defaultValue={editData?.description}
                ></textarea>
              </label>
              <span>Upload Thumbnail</span>
              <div className="d-flex align-items-center border-fileInput">
                <label htmlFor="thumbnail" className="d-flex flex-column w-100">
                  <div className="upload-btn-wrapper w-100 h-65 d-flex align-items-center">
                    <button className="btn-2 d-flex align-items-center">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        Upload Thumbnail
                      </div>
                    </button>
                    <input
                      type="file"
                      name="thumbnail"
                      id="thumbnail"
                      onChange={(e) => fileEditInputHandler(e, "image")}
                    />
                  </div>
                </label>
                <div className="me-2">
                  {imageLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <a
                      href={editData?.thumbnail}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  )}
                </div>
              </div>
              <span>Upload Video</span>
              <div className="d-flex align-items-center border-fileInput">
                <label htmlFor="video" className="d-flex flex-column w-100">
                  <div className="upload-btn-wrapper w-100 h-65 d-flex align-items-center">
                    <button className="btn-2 d-flex align-items-center">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        Upload Video
                      </div>
                    </button>
                    <input
                      type="file"
                      name="video"
                      id="video"
                      onChange={(e) => fileEditInputHandler(e, "video")}
                    />
                  </div>
                </label>
                <div className="me-2">
                  {videoLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <a href={editData?.video} target="_blank" rel="noreferrer">
                      View
                    </a>
                  )}
                </div>
              </div>
              <div>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "none",
                    backgroundColor: "#521986",
                    color: "white",
                    outline: "none",
                    borderRadius: "5px",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  {loading3 ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "1px solid #521986",
                    backgroundColor: "white",
                    color: "#521986",
                    outline: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => setShow2(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Practice;
