import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/blogs.css";
import "../src/styles/newBlog.css";
import Login from "./scenes/login";
import ProtectedRoute from "./utils/ProtectedRoutes";
import Orders from "./scenes/orders";
import Customers from "./scenes/customers";
import Invoice from "./scenes/invoices";
import Users from "./scenes/Users";
import Profile from "./scenes/profile";
import Products from "./scenes/products";
import CreateProduct from "./scenes/product-create";
import Emails from "./scenes/emails";
import Chats from "./scenes/chats";
import Pricing from "./scenes/pricing-table";
import "react-quill/dist/quill.snow.css";
import BestPractice from "./scenes/BestPractice";
import Bulks from "./scenes/bulks";
import Team from "./scenes/team";
import UnProtectedRouter from "./utils/UnprotectedRoutes";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  // console.log("route- ",location.pathname);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {location.pathname === "/" ? "" : <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {location.pathname === "/" ? (
              ""
            ) : (
              <Topbar setIsSidebar={setIsSidebar} />
            )}

            <Routes>
              <Route element={<UnProtectedRouter />}>
                <Route path="/" element={<Login />} />
              </Route>

              <Route element={<ProtectedRoute />}>
                <Route path="/resumatic/dashboard" element={<Dashboard />} />
                <Route path="/resumatic/orders" element={<Orders />} />
                <Route path="/resumatic/customer" element={<Customers />} />
                <Route path="/resumatic/customer/bulks" element={<Bulks />} />
                <Route path="/resumatic/customer/team" element={<Team />} />
                <Route path="/resumatic/invoice" element={<Invoice />} />
                <Route path="/resumatic/users" element={<Users />} />
                <Route path="/resumatic/profile" element={<Profile />} />
                <Route path="/resumatic/products" element={<Products />} />
              <Route path="/resumatic/products/:id" element={<BestPractice />} />
                <Route
                  path="/resumatic/products/create"
                  element={<CreateProduct />}
                />

                <Route path="/resumatic/chats" element={<Chats />} />
                <Route path="/resumatic/emails" element={<Emails />} />
                <Route path="/resumatic/pricing-table" element={<Pricing />} />
              </Route>
              <Route path="*" element={<h1>Page not found!!</h1>} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
