import { Box } from "@mui/material";

const Emails = () => {
  return (
    <Box p="20px">
      <div>Email Page</div>
    </Box>
  );
};

export default Emails;
