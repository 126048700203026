import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const UnProtectedRouter = () => {
  const token = localStorage.getItem("resumatic_token");
  return token && token?.length > 0 ? (
    <Navigate to="/resumatic/dashboard" />
  ) : (
    <Outlet />
  );
};

export default UnProtectedRouter;
