import axios from "axios";


const API = axios.create({
  baseURL: "https://resumatic-api.viosa.in",
  //   headers: {
  //     Authorization: `Bearer ${Cookies.get("unicapAdminToken")
  //       ?.replace(`"`, "")
  //       ?.replace(`"`, "")}`,
  //   },
});


export const getCustomerReq = (pageNumber, limit, search) =>
  API.get(`/admin/users?pageNumber=${pageNumber}&pageSize=${limit}&search=${search}`);
export const addCustomerReq = (data) => API.post("/admin/user", data);
export const updateCustomerReq = (id, data) => API.post(`/admin/user/${id}`, data);
export const deleteCustomerReq = (id) => API.delete(`/admin/user/${id}`);
export const assignPlanReq = (data) => API.post("/admin/assign", data);
export const renewPlanReq = (data) => API.post("/admin/renew", data);
export const cancelPlanReq = (data) => API.post("/admin/cancel", data); 

export const addPlanReq = (data) => API.post("/admin/plan", data);
export const getPlanReq = () => API.get("/admin/plan");
export const updatePlanReq = (id, data) => API.post(`/admin/plan/${id}`, data);
export const deletePlanReq = (id) => API.delete(`/admin/plan/${id}`);


export const getInvoiceReq = (page, search) =>
  API.get(`/admin/payments?pageNumber=${page}&search=${search}`);



export const addPracticeReq = (data) => API.post("/admin/practices", data);
export const updatePracticeReq = (id, data) => API.post(`/admin/practices/${id}`, data);
export const getPracticeReq = () => API.get("/admin/practices");
export const deletePracticeReq = (id) => API.delete(`/admin/practices/${id}`);
export const getPracticeByIdReq = (id) => API.get(`/admin/practices/${id}`);


export const bulksUploadReq = (data) => API.post("/admin/bulk-user", data);


export const addTeamReq = (data) => API.post("/tm/team", data);
export const getAllTeamReq = () => API.get("/tm/team");
export const addUserInTeamReq = (data) => API.patch("/tm/team/add-user", data);
export const removeUserInTeamReq = (data) => API.post("/tm/team/remove-user", data); 
export const getAllUserReq = () => API.get("/tm/users");


export const dashboardCountReq = () => API.get("/admin/dashboard");
export const getDashBoardSalesReq = () => API.get("/admin/planSales");
export const getDashBoardReviewReq = () => API.get("/admin/reviews");


export const deleteBulkUsers = (data) => API.post("/admin/users", {users: data});
export const deleteBulkTeams = (data) => API.post(`/tm/team/remove-bulk`, data);
export const deleteTeams = (id) => API.delete(`/tm/team/${id}`);
export const editTeam = (data) => API.patch("/tm/team/edit", data);
