import { Box, Typography } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
// import LineChart from "../../components/LineChart";
import reveiw from "../../images/review.png";
import SearchIcon from "@mui/icons-material/Search";
import { Modal, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import exportFromJSON from "export-from-json";
import {
  addCustomerReq,
  addTeamReq,
  addUserInTeamReq,
  deleteBulkUsers,
  getAllTeamReq,
  getCustomerReq,
  getPlanReq,
} from "../../apis";
import Loader from "../../ui/Loader";
import Table from "../../components/Table";
import { useNavigate } from "react-router-dom";

const Customers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [show2, setShow2] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedCheck, setSelectedCheck] = useState([]);
  const [teamAdd, setTeamAdd] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    aicredits: 0
  });
  const [addCustomerData, setAddCustomerData] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleSubmitCustomer = async (e) => {
    e.preventDefault();
    try {
      setLoading2(true);
      const res = await addCustomerReq(addCustomerData);
      if (res?.data?.success) {
        setShow(false);
        setLoading2(false);
        alert(res?.data?.message);
        setCustomers([res?.data?.data, ...customers]);
      }
      console.log(res);
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  const getCustomersData = async () => {
    try {
      let memeberArr = [];
      setLoading(true);
      const res = await getCustomerReq(page, 10, "");
      if (res?.data?.success) {
        setCustomers(res?.data?.data?.user);
        setPage(res?.data?.data?.page);
        setTotalPage(res?.data?.data?.pages);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const inputChangeHandler = (e) => {
    setAddCustomerData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const seachHandler = async (e) => {
    try {
      const res = await getCustomerReq(page, 10, e.target.value);
      if (res?.data?.success) {
        setCustomers(res?.data?.data?.user);
        setPage(res?.data?.data?.page);
        setTotalPage(res?.data?.data?.pages);
      }
    } catch (error) {}
  };

  const getPlanData = async () => {
    try {
      setLoading(true);
      const res = await getPlanReq();
      if (res?.data?.success) {
        setPlans(res?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getCustomersData();
    getPlanData();
  }, [page]);

  const downloadUsers = () => {
    const fileName = `Users_${new Date(Date.now())}`;
    const exportType = exportFromJSON.types.csv;
    const userWithouPassword = customers.map((user) => {
      return {
        ID: user?._id,
        Name: user?.name,
        Role: user?.role,
        Email: user.email,
        SerialNo: user?.serialNumber,
        Phone: user.phoneNumber,
        Source: user?.source,
        Designation: user.designation,
        Education: user?.education,
        WorkExperience: user?.workExperience,
        CRM: user?.crm,
        CreatedAt: user?.createdAt,
        UpdatedAt: user?.updatedAt,
        AccessCode: user?.access_code,
        CD: user?.cd,
        CurrentState: user?.current_stage,
        CurrentStatus: user?.current_status,
      };
    });
    const data = userWithouPassword;
    exportFromJSON({ data, fileName, exportType });
  };

  const deleteBulkHandler = async () => {
    if (window.confirm()) {
      try {
        const res = await deleteBulkUsers(selectedCheck);
        alert("Deleted Successfull");
        getCustomersData();
        getPlanData();
      } catch (error) {
        alert("Something went wrong!");
      }
    }
  };

  return (
    <Box
      p="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
            marginBottom="20px"
          >
            <Box
              gridColumn="span 7"
              gridRow="span 3"
              backgroundColor={colors.primary[400]}
              border="1px solid #FFA303"
              borderRadius="5px"
            >
              <Box
                mt="25px"
                p="0 30px"
                display="flex "
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    New Customer
                  </Typography>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={colors.grey[400]}
                  >
                    ₹ 392472783
                  </Typography>
                </Box>
                <Box>
                  <select className="month-selector" name="month" id="month">
                    <option value="">JAN</option>
                    <option value="">FEB</option>
                    <option value="">MAR</option>
                    <option value="">APR</option>
                    <option value="">MAY</option>
                    <option value="">JUN</option>
                    <option value="">JULY</option>
                    <option value="">AUG</option>
                    <option value="">SEPT</option>
                    <option value="">OCT</option>
                    <option value="">NOV</option>
                    <option value="">DEC</option>
                  </select>
                </Box>
              </Box>
              <Box height="350px">{/* <LineChart isDashboard={true} /> */}</Box>
            </Box>
            <Box
              gridColumn="span 5"
              gridRow="span 3"
              backgroundColor={colors.primary[400]}
              border="1px solid #FFA303"
              borderRadius="5px"
            >
              <Box
                mt="25px"
                px="20px"
                display="flex "
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Customer Rating
                  </Typography>
                </Box>
                <Box>
                  <button className="view-all-btn">View All</button>
                </Box>
              </Box>
              <Box className="d-flex flex-column" height="350px">
                <div className="px-3 my-2">
                  <div className="d-flex">
                    <img className="me-2" src={reveiw} alt="review" />
                    <div>
                      <h6>Chand Khanna</h6>
                    </div>
                  </div>
                  <p>
                    Facilisi at justo sodales dui ipsum tortor. Maecenas
                    volutpat consectetur arcu mi Facilisi at justo sodales dui
                    ipsum tortor. Maecenas volutpat consectetur arcu mi dfds
                    fsfds dfs....
                  </p>
                </div>
                <div className="px-3 my-3">
                  <div className="d-flex">
                    <img className="me-2" src={reveiw} alt="review" />
                    <div>
                      <h6>Chand Khanna</h6>
                    </div>
                  </div>
                  <p>
                    Facilisi at justo sodales dui ipsum tortor. Maecenas
                    volutpat consectetur arcu mi Facilisi at justo sodales dui
                    ipsum tortor. Maecenas volutpat consectetur arcu mi dfds
                    fsfds dfs....
                  </p>
                </div>
                <div className="px-3 my-3">
                  <div className="d-flex">
                    <img className="me-2" src={reveiw} alt="review" />
                    <div>
                      <h6>Chand Khanna</h6>
                    </div>
                  </div>
                  <p>
                    Facilisi at justo sodales dui ipsum tortor. Maecenas
                    volutpat consectetur arcu mi Facilisi at justo sodales dui
                    ipsum tortor. Maecenas volutpat consectetur arcu mi dfds
                    fsfds dfs....
                  </p>
                </div>
              </Box>
            </Box>
          </Box>
          <Box className="d-flex align-items-center justify-content-between mb-5">
            <div className="search-Input">
              <input type="text" placeholder="Search" onChange={seachHandler} />
              <SearchIcon className="search-icon" />
            </div>

            <button
              onClick={() => setShow(true)}
              style={{
                marginLeft: "20px",
                width: "200px",
                height: "40px",
                border: "none",
                backgroundColor: "#521986",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Add Customer
            </button>

            <button
              onClick={() => navigate("/resumatic/customer/bulks")}
              style={{
                marginLeft: "20px",
                width: "200px",
                height: "40px",
                border: "none",
                backgroundColor: "#521986",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Add Bulk
            </button>

            <button
              onClick={() => navigate("/resumatic/customer/team")}
              style={{
                marginLeft: "20px",
                width: "200px",
                height: "40px",
                border: "none",
                backgroundColor: "#521986",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Add Team
            </button>

            <button
              onClick={downloadUsers}
              style={{
                marginLeft: "20px",
                width: "200px",
                height: "40px",
                border: "none",
                backgroundColor: "#521986",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Export
            </button>

            <button
              onClick={deleteBulkHandler}
              disabled={selectedCheck?.length > 0 ? false : true}
              style={{
                marginLeft: "20px",
                padding: "0 10px",
                height: "40px",
                border: "none",
                backgroundColor: "#521986",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Delete
            </button>

            <Modal show={show} onHide={() => setShow(false)} centered>
              <Modal.Body>
                <div className="p-2">
                  <h3 className="text-bold mb-3">Add Customer</h3>
                  <form onSubmit={handleSubmitCustomer} className="w-100">
                    <label htmlFor="name" className="d-flex flex-column mb-3">
                      <span>Name</span>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        className="modal-input"
                        name="name"
                        onChange={inputChangeHandler}
                        required
                      />
                    </label>
                    <label htmlFor="email" className="d-flex flex-column mb-3">
                      <span>Email</span>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        className="modal-input"
                        name="email"
                        onChange={inputChangeHandler}
                        required
                      />
                    </label>
                    <label
                      htmlFor="phoneNumber"
                      className="d-flex flex-column mb-3"
                    >
                      <span>Mobile</span>
                      <input
                        type="text"
                        placeholder="Enter Mobile"
                        className="modal-input"
                        name="phoneNumber"
                        onChange={inputChangeHandler}
                        required
                      />
                    </label>
                    <label
                      htmlFor="password"
                      className="d-flex flex-column mb-3"
                    >
                      <span>Password</span>
                      <input
                        type="password"
                        placeholder="Enter Password"
                        className="modal-input"
                        name="password"
                        onChange={inputChangeHandler}
                        required
                      />
                    </label>

                    <div>
                      <button
                        style={{
                          width: "150px",
                          height: "38px",
                          border: "none",
                          backgroundColor: "#521986",
                          color: "white",
                          outline: "none",
                          borderRadius: "5px",
                          marginRight: "30px",
                          cursor: "pointer",
                        }}
                        type="submit"
                      >
                        {loading2 ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </button>
                      <button
                        style={{
                          width: "150px",
                          height: "38px",
                          border: "1px solid #521986",
                          backgroundColor: "white",
                          color: "#521986",
                          outline: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        type="button"
                        onClick={() => setShow(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          </Box>

          <Table
            page={page}
            customers={customers}
            setPage={setPage}
            totalPage={totalPage}
            getCustomersData={getCustomersData}
            plans={plans}
            setSelectedCheck={setSelectedCheck}
            selectedCheck={selectedCheck}
          />
        </>
      )}
    </Box>
  );
};

export default Customers;
