import React, { useEffect, useState } from 'react';
import profile from "../../images/profile.png";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Pagination } from '@mui/material';
import { Nav, NavDropdown } from 'react-bootstrap';
import { getInvoiceReq } from '../../apis';

const Invoice = () => {
  return (
    <Box
      p="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <Box className="d-flex align-items-center justify-content-between mb-5">
        <div>
          <h6 className="heading1">Invoice</h6>
          <span className="heading">Overall Invoice</span>
        </div>
        <div className="search-Input">
          <input type="text" placeholder="Search" />
          <SearchIcon className="search-icon" />
        </div>
        <select className="month-selector mx-2" name="month" id="month">
          <option value="">JAN</option>
          <option value="">FEB</option>
          <option value="">MAR</option>
          <option value="">APR</option>
          <option value="">MAY</option>
          <option value="">JUN</option>
          <option value="">JULY</option>
          <option value="">AUG</option>
          <option value="">SEPT</option>
          <option value="">OCT</option>
          <option value="">NOV</option>
          <option value="">DEC</option>
        </select>

        <select className="month-selector" name="month" id="month">
          <option value="">JAN</option>
          <option value="">FEB</option>
          <option value="">MAR</option>
          <option value="">APR</option>
          <option value="">MAY</option>
          <option value="">JUN</option>
          <option value="">JULY</option>
          <option value="">AUG</option>
          <option value="">SEPT</option>
          <option value="">OCT</option>
          <option value="">NOV</option>
          <option value="">DEC</option>
        </select>
      </Box>

      <Box paddingBottom="20px">
        <div className="mt-3">
          <div className="d-flex justify-content-between tableHeaders">
            <span>Avatar</span>
            <span>Invoices</span>
            <span>Name</span>
            <span>Product Name</span>
            <span>Price</span>
            <span>Date</span>
            <span>Expiry Date</span>
            <span>Actions</span>
          </div>

          <div className="tableRows">
            <span>
              <img src={profile} alt="" />
            </span>
            <span style={{ color: "#ffba42" }}>Invoice-222</span>
            <span>Chandni</span>
            <span>Resumatic</span>
            <span>Price</span>
            <span>Date</span>
            <span>Date</span>
            <span>
              <Nav>
                <NavDropdown
                  title={<KeyboardArrowDownIcon style={{ color: "#FFA303" }} />}
                  id="basic-nav-dropdown"
                >
                  <div className="dropdown_item d-flex flex-column p-3">
                    <span>View Details</span>
                    <span>Send</span>
                    <span>Download</span>
                    <span>Print</span>
                    <span>Detete</span>
                  </div>
                </NavDropdown>
              </Nav>
            </span>
          </div>
          <div className="tableRows">
            <span>
              <img src={profile} alt="" />
            </span>
            <span style={{ color: "#ffba42" }}>Invoice-222</span>
            <span>Chandni</span>
            <span>Resumatic</span>
            <span>Price</span>
            <span>Date</span>
            <span>Date</span>
            <span>
              <Nav>
                <NavDropdown
                  title={<KeyboardArrowDownIcon style={{ color: "#FFA303" }} />}
                  id="basic-nav-dropdown"
                >
                  <div className="dropdown_item d-flex flex-column p-3">
                    <span>View Details</span>
                    <span>Send</span>
                    <span>Download</span>
                    <span>Print</span>
                    <span>Detete</span>
                  </div>
                </NavDropdown>
              </Nav>
            </span>
          </div>
          <div className="tableRows">
            <span>
              <img src={profile} alt="" />
            </span>
            <span style={{ color: "#ffba42" }}>Invoice-222</span>
            <span>Chandni</span>
            <span>Resumatic</span>
            <span>Price</span>
            <span>Date</span>
            <span>Date</span>
            <span>
              <Nav>
                <NavDropdown
                  title={<KeyboardArrowDownIcon style={{ color: "#FFA303" }} />}
                  id="basic-nav-dropdown"
                >
                  <div className="dropdown_item d-flex flex-column p-3">
                    <span>View Details</span>
                    <span>Send</span>
                    <span>Download</span>
                    <span>Print</span>
                    <span>Detete</span>
                  </div>
                </NavDropdown>
              </Nav>
            </span>
          </div>
        </div>
      </Box>

      <div className="d-flex justify-content-end">
        <Pagination
          count={1}
          variant="outlined"
          shape="rounded"
          sx={{
            ".MuiPaginationItem-rounded": {
              color: "#ee9803",
              border: "1px solid #ee9803",
            },
            ".Mui-selected": {
              background: "#ee9803 !important",
              color: "white !important",
            },
            ".MuiPaginationItem-rounded:hover": {
              background: "#ee9803",
              color: "white",
            },
            ".MuiPaginationItem-rounded:active": {
              background: "#ee9803 !important",
              color: "white !important",
            },
          }}
        />
      </div>
    </Box>
  );
}

export default Invoice;