import { Box, Pagination } from "@mui/material";
import React, { useState } from "react";
import { Modal, Nav, NavDropdown, Spinner } from "react-bootstrap";
import profile from "../images/profile.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  assignPlanReq,
  cancelPlanReq,
  deleteCustomerReq,
  renewPlanReq,
  updateCustomerReq,
} from "../apis";

const Table = ({
  page,
  customers,
  setPage,
  totalPage,
  getCustomersData,
  plans,
  setSelectedCheck,
  selectedCheck,
}) => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [modalUser, setModalUser] = useState({});
  const [editCustomerData, setEditCustomerData] = useState({
    _id: "",
    email: "",
    name: "",
    phoneNumber: "",
    aicredits: 0
    // password: ""
  });
  const [assignPlanData, setAssignPlanData] = useState({});
  const [changePassword, setChangePassword] = useState({
    _id: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const pageChangeHandler = (e, val) => {
    setPage(val);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await updateCustomerReq(editCustomerData._id, {
        name: editCustomerData.name,
        email: editCustomerData?.email,
        phoneNumber: editCustomerData.phoneNumber,
        aicredits: editCustomerData.aicredits,
      });
      console.log(res);
      if (res?.data?.success) {
        setShow(false);
        setLoading(false);
        alert(res?.data?.message);
        getCustomersData();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const editChangeHandler = (e) => {
    setEditCustomerData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleDelete = async (id) => {
    const action = window.confirm("Are you sure you want to delete?");
    if (action) {
      try {
        const res = await deleteCustomerReq(id);
        if (res?.data?.success) {
          alert(res?.data?.message);
          getCustomersData();
        } else {
          alert("Something went wrong!");
        }
      } catch (error) {
        console.log(error);
        alert("Something went wrong!");
      }
    }
  };

  const passwordChangeHandler = (e) => {
    setChangePassword((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const passwordChange = async (e) => {
    e.preventDefault();
    if (changePassword?.password === changePassword.confirmPassword) {
      setLoading(true);
      try {
        const res = await updateCustomerReq(changePassword?._id, {
          password: changePassword?.password,
        });
        if (res?.data?.success) {
          alert("Password Successfully Changed");
          setShow2(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert("Something went wrong!");
      }
    } else {
      alert("Password and Confirm Password Mismatch!");
    }
  };

  const assignPlanHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading2(true);
      const res = await assignPlanReq(assignPlanData);
      if (res?.data?.success) {
        setShow3(false);
        alert(res?.data?.message);
        getCustomersData();
        setAssignPlanData({});
      }
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
      alert("Something went wrong!");
    }
  };

  const cancelPlanHandler = async (id) => {
    try {
      setLoading3(true);
      const res = await cancelPlanReq({ id: id });
      if (res?.data?.success) {
        alert(res?.data?.message);
        setShow3(false);
        getCustomersData();
        setAssignPlanData({});
      }
      setLoading3(false);
    } catch (error) {
      setLoading3(false);
      alert("Something went wrong!");
    }
  };

  const renewPlanHandler = async (id) => {
    try {
      setLoading4(true);
      const res = await renewPlanReq({ id: id });
      if (res?.data?.success) {
        alert(res?.data?.message);
        setShow3(false);
        getCustomersData();
        setAssignPlanData({});
      }
      setLoading4(false);
    } catch (error) {
      setLoading4(false);
      alert("Something went wrong!");
    }
  };
  const selectAllHandler = (e) => {
    let allChecked = [];
    if (e.target.checked) {
      customers?.forEach((user) => {
        allChecked.push(String(user?._id));
      });
      setSelectedCheck(allChecked);
    } else {
      setSelectedCheck([]);
    }
  };

  const singleCheckHandler = (e, id) => {
    console.log(id);
    if (e.target.checked) {
      if (!selectedCheck?.includes(String(id))) {
        setSelectedCheck([...selectedCheck, String(id)]);
      }
    } else {
      setSelectedCheck(
        selectedCheck?.filter((item) => String(item) !== String(id))
      );
    }
  };


  return (
    <>
      <Box>
        <div className="mt-3">
          <div className="d-flex justify-content-between tableHeaders">
            <span style={{ width: "5%" }}>
              <input
                onChange={selectAllHandler}
                type="checkbox"
                name=""
                id=""
              />
            </span>
            <span>Avatar</span>
            <span>Serial No.</span>
            <span>Name</span>
            <span>Email</span>
            <span>Phone</span>
            <span>Designation</span>
            <span>Team</span>
            <span>Actions</span>
          </div>

          {customers?.map((data) => {
            return (
              <div className="tableRows d-flex align-items-center">
                <span style={{ width: "5%" }}>
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={
                      selectedCheck.includes(String(data?._id)) ? true : false
                    }
                    onChange={(e) => singleCheckHandler(e, data?._id)}
                  />
                </span>
                <span>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      objectFit: "contain",
                    }}
                  >
                    <img
                      src={data?.profileImage ? data?.profileImage : profile}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </span>
                <span>{data?.serialNumber}</span>
                <span>{data?.name}</span>
                <span>{data?.email}</span>
                <span>{data?.phoneNumber}</span>
                <span>{data?.designation}</span>
                <span>{data?.teamName}</span>
                <span>
                  <Nav>
                    <NavDropdown
                      title={
                        <KeyboardArrowDownIcon style={{ color: "#FFA303" }} />
                      }
                      id="basic-nav-dropdown"
                    >
                      <div className="dropdown_item d-flex flex-column p-3">
                        <span
                          style={{ padding: "10px 5px", cursor: "pointer" }}
                          onClick={() => {
                            setEditCustomerData(data);
                            setShow(true);
                          }}
                        >
                          Edit
                        </span>
                        <span
                          onClick={() => handleDelete(data?._id)}
                          style={{ padding: "10px 5px", cursor: "pointer" }}
                        >
                          Delete
                        </span>
                        <span
                          onClick={() => {
                            setChangePassword((prev) => {
                              return {
                                ...prev,
                                _id: data?._id,
                              };
                            });
                            setShow2(true);
                          }}
                          style={{ padding: "10px 5px", cursor: "pointer" }}
                        >
                          Change Password
                        </span>
                        <span
                          onClick={() => {
                            setModalUser(data);
                            setAssignPlanData((prev) => {
                              return {
                                ...prev,
                                id: data?._id,
                              };
                            });
                            setShow3(true);
                          }}
                          style={{ padding: "10px 5px", cursor: "pointer" }}
                        >
                          Manage Plan
                        </span>
                      </div>
                    </NavDropdown>
                  </Nav>
                </span>
              </div>
            );
          })}
        </div>
      </Box>
      <div className="d-flex justify-content-end pb-3">
        <Pagination
          count={totalPage}
          page={page}
          variant="outlined"
          onChange={pageChangeHandler}
          shape="rounded"
          sx={{
            ".MuiPaginationItem-rounded": {
              color: "#ee9803",
              border: "1px solid #ee9803",
            },
            ".Mui-selected": {
              background: "#ee9803 !important",
              color: "white !important",
            },
            ".MuiPaginationItem-rounded:hover": {
              background: "#ee9803",
              color: "white",
            },
            ".MuiPaginationItem-rounded:active": {
              background: "#ee9803 !important",
              color: "white !important",
            },
          }}
        />
      </div>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Update Customer</h3>
            <form onSubmit={handleEdit} className="w-100">
              <label htmlFor="name" className="d-flex flex-column mb-3">
                <span>Name</span>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="modal-input"
                  name="name"
                  defaultValue={editCustomerData.name}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              <label htmlFor="email" className="d-flex flex-column mb-3">
                <span>Email</span>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="modal-input"
                  name="email"
                  defaultValue={editCustomerData.email}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              <label htmlFor="phoneNumber" className="d-flex flex-column mb-3">
                <span>Mobile</span>
                <input
                  type="text"
                  placeholder="Enter Mobile"
                  className="modal-input"
                  name="phoneNumber"
                  defaultValue={editCustomerData.phoneNumber}
                  onChange={editChangeHandler}
                  required
                />
              </label>

              <label htmlFor="aicredits" className="d-flex flex-column mb-3">
                <span>AI Credits</span>
                <input
                  type="text"
                  placeholder="Enter Mobile"
                  className="modal-input"
                  name="aicredits"
                  defaultValue={editCustomerData.aicredits}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              {/* <label htmlFor="phoneNumber" className="d-flex flex-column mb-3">
                <span>Password</span>
                <input
                  type="text"
                  placeholder="Enter Password"
                  className="modal-input"
                  name="password"
                  defaultValue={editCustomerData.password}
                  onChange={editChangeHandler}
                  required
                />
              </label> */}
              <div>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "none",
                    backgroundColor: "#521986",
                    color: "white",
                    outline: "none",
                    borderRadius: "5px",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "1px solid #521986",
                    backgroundColor: "white",
                    color: "#521986",
                    outline: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={() => setShow2(false)} centered>
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Change Password</h3>
            <form onSubmit={passwordChange} className="w-100">
              <label htmlFor="name" className="d-flex flex-column mb-3">
                <span>Password</span>
                <input
                  type="password"
                  placeholder="Enter Password"
                  className="modal-input"
                  name="password"
                  onChange={passwordChangeHandler}
                  required
                />
              </label>
              <label htmlFor="password" className="d-flex flex-column mb-3">
                <span>Email</span>
                <input
                  type="password"
                  placeholder="Enter Confirm Password"
                  className="modal-input"
                  name="confirmPassword"
                  onChange={passwordChangeHandler}
                  required
                />
              </label>
              <div>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "none",
                    backgroundColor: "#521986",
                    color: "white",
                    outline: "none",
                    borderRadius: "5px",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "1px solid #521986",
                    backgroundColor: "white",
                    color: "#521986",
                    outline: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => setShow2(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show3}
        onHide={() => {
          setShow3(false);
          setAssignPlanData({});
          setModalUser({});
        }}
        centered
      >
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Plan Management</h3>

            {modalUser?.subscription && (
              <>
                <h6 className="text-bold mb-3">User Plan</h6>
                <div
                  style={{
                    border: "1px solid #521986",
                    borderRadius: "5px",
                    padding: "10px",
                    marginBottom: "30px",
                  }}
                >
                  <div className="d-flex justify-content-between mb-3">
                    <span>{modalUser?.subscription?.planName}</span>
                    <span>
                      {modalUser?.subscription?.planType === "month"
                        ? "Monthly"
                        : "Annually"}
                    </span>
                    <span>₹ {modalUser?.subscription?.amount}</span>
                  </div>

                  <div className="d-flex justify-content-between px-1">
                    {modalUser?.subscription &&
                      !modalUser?.subscription?.isActive && (
                        <button
                          style={{
                            width: "150px",
                            height: "38px",
                            border: "none",
                            backgroundColor: "#521986",
                            color: "white",
                            outline: "none",
                            borderRadius: "5px",
                            marginRight: "30px",
                            cursor: "pointer",
                          }}
                          onClick={() => renewPlanHandler(modalUser?._id)}
                        >
                          {loading4 ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Renew"
                          )}
                        </button>
                      )}
                    {modalUser?.subscription &&
                      modalUser?.subscription?.isActive && (
                        <button
                          style={{
                            width: "150px",
                            height: "38px",
                            border: "1px solid #521986",
                            backgroundColor: "white",
                            color: "#521986",
                            outline: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => cancelPlanHandler(modalUser?._id)}
                        >
                          {loading3 ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Cancel Plan"
                          )}
                        </button>
                      )}
                  </div>
                </div>
              </>
            )}

            <form onSubmit={assignPlanHandler}>
              <h6 className="text-bold mb-3">Assign a Plan</h6>
              <label className="d-flex flex-column mb-3" htmlFor="planAssign">
                <span style={{ fontSize: "14px" }}>Select Plan</span>
                <select
                  onChange={(e) =>
                    setAssignPlanData((prev) => {
                      return {
                        ...prev,
                        planId: e.target.value,
                      };
                    })
                  }
                  style={{
                    height: "38px",
                    outline: "none",
                    padding: "0 10px",
                    border: "1px solid #290c43",
                    borderRadius: "5px",
                    fontSize: "16px",
                  }}
                  name="plan"
                  id="plan"
                  required
                >
                  <option value="">Select Plan</option>
                  {plans?.map((item) => {
                    return <option value={item?._id}>{item?.name}</option>;
                  })}
                </select>
              </label>
              {/* <label className="d-flex flex-column mb-3" htmlFor="planAssign">
                <span style={{ fontSize: "14px" }}>
                  Type of Plan (Monthly / Annually)
                </span>
                <select
                  onChange={(e) =>
                    setAssignPlanData((prev) => {
                      return {
                        ...prev,
                        planType: e.target.value,
                      };
                    })
                  }
                  style={{
                    height: "38px",
                    outline: "none",
                    padding: "0 10px",
                    border: "1px solid #290c43",
                    borderRadius: "5px",
                    fontSize: "16px",
                  }}
                  name="plan"
                  id="plan"
                  required
                >
                  <option value="">Select Plan Type</option>
                  <option value="month">Monthly</option>
                  <option value="annual">Annually</option>
                </select>
              </label> */}

              <div>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "none",
                    backgroundColor: "#521986",
                    color: "white",
                    outline: "none",
                    borderRadius: "5px",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  {loading2 ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "1px solid #521986",
                    backgroundColor: "white",
                    color: "#521986",
                    outline: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => {
                    setShow3(false);
                    setAssignPlanData({});
                    setModalUser({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Table;
