import React, { useState } from "react";
import profile from "../../images/profile.png";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Pagination } from "@mui/material";
import { Modal, Nav, NavDropdown, Spinner } from "react-bootstrap";
import {
  addPlanReq,
  deletePlanReq,
  getPlanReq,
  updatePlanReq,
} from "../../apis";
import { useEffect } from "react";
import PricingTable from "../../components/PricingTable";
import { useLayoutEffect } from "react";
import Loader from "../../ui/Loader";

const Pricing = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [plans, setPlans] = useState([]);
  const [planData, setPlanData] = useState({
    name: "",
    aicredits: "",
    price: "",
    totalResume: "",
    totalCover: "",
    isAnnual: true,
  });

  const getPlanData = async () => {
    try {
      setLoading(true);
      const res = await getPlanReq();
      console.log(res);
      if (res?.data?.success) {
        setPlans(res?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmitPlan = async (e) => {
    e.preventDefault();
    try {
      setLoading2(true);
      const res = await addPlanReq(planData);
      console.log(res);
      if (res?.data?.success) {
        setShow(false);
        setLoading2(false);
        alert(res?.data?.message);
        setPlans([res?.data?.data, ...plans]);
      }
      console.log(res);
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  const inputChangeHandler = (e) => {
    setPlanData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const labelChangeHandler = (e) => {
    if (e.target.name === "annual") {
      setPlanData((prev) => {
        return {
          ...prev,
          isAnnual: true,
        };
      });
    } else {
      setPlanData((prev) => {
        return {
          ...prev,
          isAnnual: false,
        };
      });
    }
  };

  useEffect(() => {
    getPlanData();
  }, []);

  return (
    <Box
      p="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <Box className="d-flex align-items-center justify-content-between mb-5">
        <div>
          <h6 className="heading1">Pricing</h6>
          <span className="heading">Plan and Pricing</span>
        </div>
        {/* <div className="search-Input">
                <input type="text" placeholder="Search" />
                <SearchIcon className="search-icon" />
              </div> */}
        <button
          onClick={() => setShow(true)}
          style={{
            marginLeft: "20px",
            width: "200px",
            height: "40px",
            border: "none",
            backgroundColor: "#521986",
            color: "white",
            outline: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Add Plan
        </button>
        <Modal show={show} onHide={() => setShow(false)} centered>
          <Modal.Body>
            <div className="p-2">
              <h3 className="text-bold mb-3">Add Plan</h3>
              <form onSubmit={handleSubmitPlan} className="w-100">
                <label htmlFor="name" className="d-flex flex-column mb-3">
                  <span>Name</span>
                  <input
                    type="text"
                    placeholder="Enter Plan Name"
                    className="modal-input"
                    name="name"
                    onChange={inputChangeHandler}
                    required
                  />
                </label>
                <label htmlFor="aicredits" className="d-flex flex-column mb-3">
                  <span>Credits</span>
                  <input
                    type="text"
                    placeholder="Enter Monthly Credits"
                    className="modal-input"
                    name="aicredits"
                    onChange={inputChangeHandler}
                    required
                  />
                </label>
                <label htmlFor="price" className="d-flex flex-column mb-3">
                  <span>Price</span>
                  <input
                    type="text"
                    placeholder="Enter Annual Price"
                    className="modal-input"
                    name="price"
                    onChange={inputChangeHandler}
                    required
                  />
                </label>
                <label
                  htmlFor="totalResume"
                  className="d-flex flex-column mb-3"
                >
                  <span>Total Resume</span>
                  <input
                    type="text"
                    placeholder="Enter No of Resume"
                    className="modal-input"
                    name="totalResume"
                    onChange={inputChangeHandler}
                    required
                  />
                </label>
                <label htmlFor="totalCover" className="d-flex flex-column mb-3">
                  <span>Total Cover Letter</span>
                  <input
                    type="text"
                    placeholder="Enter No of Cover Letter"
                    className="modal-input"
                    name="totalCover"
                    onChange={inputChangeHandler}
                    required
                  />
                </label>
                <label htmlFor="isAnnual" className="d-flex flex-row mb-3">
                  <div className="d-flex align-items-center me-4">
                    <input
                      type="radio"
                      name="annual"
                      id="annual"
                      checked={planData?.isAnnual ? true : false}
                      onChange={labelChangeHandler}
                    />
                    <label htmlFor="annual">Annual</label>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="month"
                      id="month"
                      checked={planData?.isAnnual ? false : true}
                      onChange={labelChangeHandler}
                    />
                    <label htmlFor="month">Monthly</label>
                  </div>
                </label>

                <div>
                  <button
                    style={{
                      width: "150px",
                      height: "38px",
                      border: "none",
                      backgroundColor: "#521986",
                      color: "white",
                      outline: "none",
                      borderRadius: "5px",
                      marginRight: "30px",
                      cursor: "pointer",
                    }}
                    type="submit"
                  >
                    {loading2 ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    style={{
                      width: "150px",
                      height: "38px",
                      border: "1px solid #521986",
                      backgroundColor: "white",
                      color: "#521986",
                      outline: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    type="button"
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <PricingTable getPlanData={getPlanData} planData={plans} />
      )}
    </Box>
  );
};

export default Pricing;
