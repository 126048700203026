import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import {
  addTeamReq,
  addUserInTeamReq,
  deleteBulkTeams,
  deleteBulkUsers,
  deleteTeams,
  editTeam,
  getAllTeamReq,
  getAllUserReq,
  getCustomerReq,
  removeUserInTeamReq,
} from "../../apis";
import { Modal, Nav, NavDropdown, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Team = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
    const [loading, setLoading] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [allTeamMembers, setAllTeamMembers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [addTeamLoading, setAddTeamLoading] = useState(false);
  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [addMemberLoading, setAddMemberLoading] = useState(false);
  const [modalUser, setModalUser] = useState([]);
  const [selectedCheck, setSelectedCheck] = useState([]);
  const [editTeamData, setEditTeamData] = useState({
    teamName: "",
    id: "",
  });

  const addTeamMemberHandler = async () => {
    try {
      setAddMemberLoading(true);
      const res = await addUserInTeamReq({
        teamId: teamId,
        teamMember: selectedTeamMembers,
      });
      alert("Team Member Added in " + res?.data?.result?.teamName);
      setAddMemberLoading(false);
      window.location.reload();
    } catch (error) {
      setAddMemberLoading(false);
      console.log(error);
    }
  };
  const addTeamHandler = async () => {
    try {
      setAddTeamLoading(true);
      const res = await addTeamReq({
        teamName: teamName,
        teamMember: [],
      });
      setAllTeams([res?.data?.result, ...allTeams]);
      setAddTeamLoading(false);
      alert("Team Added!");
    } catch (error) {
      setAddTeamLoading(false);
      alert("Something went wrong!");
    }
  };

  const teamChangeHandler = (item) => {
    const user1 = customers?.filter((data) =>
      item?.some((data2) => data2?.value === data?._id)
    );
    let myTeam = [];
    user1?.forEach((item) => {
      myTeam.push({
        name: item?.name,
        email: item?.email,
        phoneNumber: item?.phoneNumber,
      });
    });
    setSelectedTeamMembers(myTeam);
  };

  const getTeamHandler = async () => {
    try {
      const res = await getAllTeamReq();
      setAllTeams(res?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomersData = async () => {
    try {
      let memeberArr = [];
      const res = await getAllUserReq();
      setCustomers(res?.data?.result);
      res?.data?.result?.forEach((item) => {
        memeberArr.push({
          label: item?.name,
          value: item?._id,
        });
      });
      setAllTeamMembers(memeberArr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id, email) => {
    setLoading3(true);
    try {
      const res = await removeUserInTeamReq({
        teamId: id,
        email: email,
      });
      console.log(res);
      setLoading3(false);
      alert("User Removed!");
      window.location.reload();
      setShow(false);
    } catch (error) {
      setLoading3(false);
      alert("Something went wrong!");
    }
  };
  useEffect(() => {
    getCustomersData();
    getTeamHandler();
  }, []);

  const deleteBulkHandler = async () => {
    if (window.confirm()) {
      try {
        const res = await deleteBulkTeams({ teams: selectedCheck });
        alert("Deleted Successfull");
        window.location.reload();
      } catch (error) {
        alert("Something went wrong!");
      }
    }
  };

  const selectAllHandler = (e) => {
    let allChecked = [];
    if (e.target.checked) {
      allTeams?.forEach((team) => {
        allChecked.push(String(team?._id));
      });
      setSelectedCheck(allChecked);
    } else {
      setSelectedCheck([]);
    }
  };

  const singleCheckHandler = (e, id) => {
    console.log(id);
    if (e.target.checked) {
      if (!selectedCheck?.includes(String(id))) {
        setSelectedCheck([...selectedCheck, String(id)]);
      }
    } else {
      setSelectedCheck(
        selectedCheck?.filter((item) => String(item) !== String(id))
      );
    }
  };

  const deleteTeamHandler = async (id) => {
    if (window.confirm()) {
      try {
        const res = await deleteTeams(id);
        alert("Deleted Successfull");
        window.location.reload();
      } catch (error) {
        alert("Something went wrong!");
      }
    }
  };

    const editChangeHandler = (e) => {
      setEditTeamData((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      });
    };

      const handleEdit = async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          const res = await editTeam({
            teamId: editTeamData?.id,
            teamName: editTeamData?.teamName
          });
            setShow2(false);
            setLoading(false);
            alert("Team Updated");
            window.location.reload();
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };
  return (
    <Box
      p="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <div className="p-2">
        <h3 className="text-bold mb-3">Add Team</h3>
        <div className="d-flex justify-content-between mb-3">
          <label
            htmlFor="teamName"
            className="d-flex flex-column mb-3 w-100 me-2"
          >
            <input
              type="text"
              placeholder="Enter Team Name"
              className="modal-input"
              name="teamName"
              onChange={(e) => setTeamName(e.target.value)}
              required
            />
          </label>
          <button
            onClick={() => addTeamHandler()}
            style={{
              width: "100px",
              height: "38px",
              border: "none",
              backgroundColor: "#521986",
              color: "white",
              outline: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {addTeamLoading ? <Spinner animation="border" size="sm" /> : "Add"}
          </button>
        </div>

        <h3 className="text-bold mb-3">Add Member in Team</h3>
        <div>
          <label
            htmlFor="teamName"
            className="d-flex flex-column mb-3 w-100 me-2"
          >
            <select
              onChange={(e) => setTeamId(e.target.value)}
              className="modal-input mb-3"
              name="team"
              id="team"
            >
              <option value="">Select Team</option>
              {allTeams?.map((team) => {
                return <option value={team?._id}>{team?.teamName}</option>;
              })}
            </select>

            <Select
              isMulti
              placeholder="Select Team Members"
              name="users"
              onChange={teamChangeHandler}
              options={allTeamMembers}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </label>

          <button
            onClick={addTeamMemberHandler}
            style={{
              width: "200px",
              height: "40px",
              border: "none",
              backgroundColor: "#521986",
              color: "white",
              outline: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {addMemberLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>

      <button
        onClick={deleteBulkHandler}
        disabled={selectedCheck.length > 0 ? false : true}
        style={{
          width: "100px",
          height: "40px",
          border: "none",
          backgroundColor: "#521986",
          color: "white",
          outline: "none",
          borderRadius: "5px",
          cursor: "pointer",
          marginLeft: "90%",
        }}
      >
        Delete
      </button>
      <Box>
        <div className="mt-3">
          <div className="d-flex justify-content-between tableHeaders">
            <span style={{ width: "5%" }}>
              {" "}
              <input
                onChange={selectAllHandler}
                type="checkbox"
                name=""
                id=""
              />
            </span>
            <span>S. No.</span>
            <span>Team Name</span>
            <span>Users</span>
          </div>

          {allTeams?.map((data, index) => {
            return (
              <div className="tableRows d-flex align-items-center justify-content-between">
                <span style={{ width: "5%" }}>
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={
                      selectedCheck.includes(String(data?._id)) ? true : false
                    }
                    onChange={(e) => singleCheckHandler(e, data?._id)}
                  />
                </span>
                <span>{index + 1}</span>
                <span>{data?.teamName}</span>
                <span>
                  <Nav>
                    <NavDropdown
                      title={
                        <KeyboardArrowDownIcon style={{ color: "#FFA303" }} />
                      }
                      id="basic-nav-dropdown"
                    >
                      <div className="dropdown_item d-flex flex-column p-3">
                        <span
                          style={{ padding: "10px 5px", cursor: "pointer" }}
                          onClick={() => {
                            setModalUser(data);
                            setShow(true);
                          }}
                        >
                          View Members
                        </span>
                        <span
                          style={{ padding: "10px 5px", cursor: "pointer" }}
                          onClick={() => {
                            setEditTeamData({
                              id: data?._id,
                              teamName: data?.teamName,
                            });
                            setShow2(true);
                          }}
                        >
                          Edit
                        </span>
                        <span
                          onClick={() => deleteTeamHandler(data?._id)}
                          style={{ padding: "10px 5px", cursor: "pointer" }}
                        >
                          Delete
                        </span>
                      </div>
                    </NavDropdown>
                  </Nav>
                </span>
              </div>
            );
          })}
        </div>
      </Box>

      <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Remove Users</h3>
            <div style={{ overflowY: "scroll", maxHeight: "600px" }}>
              {modalUser?.teamMembers?.length > 0 ? (
                modalUser?.teamMembers?.map((item, index) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <span>{index + 1}</span>
                      <span>{item?.name}</span>
                      <span>{item?.email}</span>
                      <button
                        style={{
                          width: "70px",
                          height: "36px",
                          border: "none",
                          backgroundColor: "red",
                          color: "white",
                          outline: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleDelete(modalUser?._id, item?.email)
                        }
                      >
                        {loading3 ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    </div>
                  );
                })
              ) : (
                <span>No User in this team</span>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={() => setShow2(false)} size="lg" centered>
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Edit Team</h3>
            <form onSubmit={handleEdit} className="w-100">
              <label htmlFor="teamName" className="d-flex flex-column mb-3">
                <span>Team Name</span>
                <input
                  type="text"
                  placeholder="Enter Team Name"
                  className="modal-input"
                  name="teamName"
                  defaultValue={editTeamData.teamName}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              <div>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "none",
                    backgroundColor: "#521986",
                    color: "white",
                    outline: "none",
                    borderRadius: "5px",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "1px solid #521986",
                    backgroundColor: "white",
                    color: "#521986",
                    outline: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => setShow2(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default Team;
