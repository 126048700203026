import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useCsvUpload } from "../../utils/bulkUploads";
import { addTeamReq, addUserInTeamReq, bulksUploadReq, getAllTeamReq } from "../../apis";
import { Modal, Nav, NavDropdown, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import profile from "../../images/profile.png";

const Bulks = () => {
  const { handleFileReader, sheetAccepted } = useCsvUpload();
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [teamId, setTeamId] = useState("");
    const [addTeamLoading, setAddTeamLoading] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [show, setShow] = useState(false);
  const [addMemberLoading, setAddMemberLoading] = useState(false);
  const navigate = useNavigate();
    const [teamName, setTeamName] = useState("");
  const ref = useRef(null);
  const handleChange = async () => {
    const [file] = ref.current.files;
    if (file) {
      handleFileReader(file, (data) => {
        setList(data);
      });
    }
  };

    const addTeamHandler = async () => {
      try {
        setAddTeamLoading(true);
        const res = await addTeamReq({
          teamName: teamName,
          teamMember: [],
        });
        setAllTeams([res?.data?.result, ...allTeams]);
        setAddTeamLoading(false);
        alert("Team Added!");
        window.location.reload();
      } catch (error) {
        setAddTeamLoading(false);
        alert("Something went wrong!");
      }
    };

  const addTeamMemberHandler = async () => {
    try {
      setAddMemberLoading(true);
      const res = await addUserInTeamReq({
        teamId: teamId,
        teamMember: selectedTeamMembers,
      });
      alert("Team Member Added in " + res?.data?.result?.teamName);
      setAddMemberLoading(false);
    } catch (error) {
      setAddMemberLoading(false);
      console.log(error);
    }
  };

  const handleBulkUpload = async () => {
    console.log(list);
    const bulkItems = [];
    list?.map((item) => {
      bulkItems.push({
        email: item?.email,
        name: item?.name,
        phoneNumber: String(item?.phoneNumber),
        password: item?.password || "",
      });
    });
    setLoading(true);
    try {
      const res = await bulksUploadReq({
        users: bulkItems,
      });
      console.log(res);
      if (res?.data?.success) {
        alert(res?.data?.message);
        setList([]);
        if (res?.data?.data?.length > 0) {
          setCustomers(res?.data?.data);
          let myTeam = [];
          res?.data?.data?.forEach((item) => {
            myTeam.push({
              name: item?.name,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
            });
          });
          setSelectedTeamMembers(myTeam);
        }
      } else {
        alert("Something went wrong!");
      }

      console.log(res);
      setLoading(false);
    } catch (error) {
      alert("Bulk Upload Failed!");
      setLoading(false);
    }
  };

  const getTeamHandler = async () => {
    try {
      const res = await getAllTeamReq();
      setAllTeams(res?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTeamHandler();
  }, []);

  return (
    <Box
      p="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <h3 className="text-bold mb-3">Add Team</h3>
      <div className="d-flex justify-content-between mb-3">
        <label
          htmlFor="teamName"
          className="d-flex flex-column mb-3 w-100 me-2"
        >
          <input
            type="text"
            placeholder="Enter Team Name"
            className="modal-input"
            name="teamName"
            onChange={(e) => setTeamName(e.target.value)}
            required
          />
        </label>
        <button
          onClick={() => addTeamHandler()}
          style={{
            width: "100px",
            height: "38px",
            border: "none",
            backgroundColor: "#521986",
            color: "white",
            outline: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {addTeamLoading ? <Spinner animation="border" size="sm" /> : "Add"}
        </button>
      </div>
      <div className="card-3 mt-4">
        <div className="d-flex justify-content-end mb-3">
          <button
            onClick={() =>
              window.open(
                "https://viosa-storage-2.s3.amazonaws.com/userlist.xlsx"
              )
            }
            style={{
              marginLeft: "20px",
              width: "200px",
              height: "40px",
              border: "none",
              backgroundColor: "#521986",
              color: "white",
              outline: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Sample File
          </button>
        </div>
        <div className="text-center bulk-container mb-4">
          <div className="card-body">
            <div className="row my-2">
              <h2>IMPORT USERS </h2>
            </div>
            <div className="row my-3">
              <h6>Import Users on your Viosa Site</h6>
            </div>
            <div className="row d-flex align-items-center justify-content-center mb-3">
              <input
                style={{ width: "225px" }}
                type="file"
                ref={ref}
                accepted={sheetAccepted}
                onChange={handleChange}
              />
            </div>
            <div className="row my-4">
              <p style={{ margin: "0px", fontSize: "18px" }}>
                We Accept Name, Email and Mobile number, Name and Email are
                required.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-center mb-3">
            <button
              onClick={handleBulkUpload}
              style={{
                marginLeft: "20px",
                width: "200px",
                height: "40px",
                border: "none",
                backgroundColor: "#521986",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Add Now"}
            </button>
          </div>
        </div>
      </div>

      {customers?.length > 0 && (
        <Box className="mt-4">
          <div>
            <button
              onClick={() => setShow(true)}
              style={{
                marginLeft: "20px",
                width: "200px",
                height: "40px",
                border: "none",
                backgroundColor: "#521986",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Add To Team"
              )}
            </button>
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between tableHeaders">
              <span>Avatar</span>
              <span>Serial No.</span>
              <span>Name</span>
              <span>Email</span>
              <span>Phone</span>
              <span>Designation</span>
            </div>

            {customers?.map((data) => {
              return (
                <div className="tableRows d-flex align-items-center">
                  <span>
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        objectFit: "contain",
                      }}
                    >
                      <img
                        src={data?.profileImage ? data?.profileImage : profile}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </span>
                  <span>{data?.serialNumber}</span>
                  <span>{data?.name}</span>
                  <span>{data?.email}</span>
                  <span>{data?.phoneNumber}</span>
                  <span>{data?.designation}</span>
                </div>
              );
            })}
          </div>
        </Box>
      )}

      <Modal show={show} onHide={() => setShow(false)} size="md" centered>
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Teams</h3>
            <label
              htmlFor="teamName"
              className="d-flex flex-column mb-3 w-100 me-2"
            >
              <select
                onChange={(e) => setTeamId(e.target.value)}
                className="modal-input mb-3"
                name="team"
                id="team"
              >
                <option value="">Select Team</option>
                {allTeams?.map((team) => {
                  return <option value={team?._id}>{team?.teamName}</option>;
                })}
              </select>
            </label>

            <button
              onClick={addTeamMemberHandler}
              style={{
                width: "200px",
                height: "40px",
                border: "none",
                backgroundColor: "#521986",
                color: "white",
                outline: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {addMemberLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default Bulks;
