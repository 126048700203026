import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import LineChart from "../../components/LineChart";
import SearchIcon from "@mui/icons-material/Search";
import "./index.css";
import PieChart from "../../components/PieChart";
import profile from "../../images/profile.png";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import reveiw from "../../images/review.png";
import { useEffect, useState } from "react";
import {
  dashboardCountReq,
  getDashBoardReviewReq,
  getDashBoardSalesReq,
  getInvoiceReq,
} from "../../apis";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [counts, setCounts] = useState({
    today: 0,
    totalOrders: 0,
    totalSales: 0,
  });
  const [sales, setSales] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const getDashboardCountHandler = async () => {
    try {
      const res = await dashboardCountReq();
      setCounts({
        today: res?.data?.today,
        totalOrders: res?.data?.totalOrders,
        totalSales: res?.data?.totalSales,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getDashboardSalesHandler = async () => {
    try {
      const res = await getDashBoardSalesReq();
      setSales(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDashboardReview = async () => {
    try {
      const res = await getDashBoardReviewReq();
      setReviews(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoiceHandler = async() => {
    try {
      const res = await getInvoiceReq("1", "");
      setInvoices(res?.data?.data?.paym);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardCountHandler();
    getDashboardSalesHandler();
    getDashboardReview();
    getInvoiceHandler();
  }, []);

  return (
    <Box
      p="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <Box mb="20px">
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={colors.primary[400]}
            border="1px solid #FFA303"
            borderRadius="5px"
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Total Sales
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color={colors.grey[400]}
                >
                  ₹ {counts?.totalSales}
                </Typography>
              </Box>
            </Box>
            <Box height="350px">{/* <LineChart isDashboard={true} /> */}</Box>
          </Box>
          <Box
            gridColumn="span 6"
            gridRow="span 3"
            backgroundColor={colors.primary[400]}
            border="1px solid #FFA303"
            borderRadius="5px"
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Total Sold
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color={colors.grey[400]}
                >
                  Overall Product Sales
                </Typography>
              </Box>
            </Box>
            <Box className="d-flex" height="350px">
              <div className="pie-container">{/*<PieChart />*/}</div>
              <div className="product-pie">
                {sales?.map((item) => {
                  return (
                    <div className="product-item">
                      <span className="product-dot"></span>
                      <span className="product-name">{item?.name}</span>
                      <span className="product-percentage">{item?.price}</span>
                      <span className="product-price">
                        {item?.aicredits} Credits
                      </span>
                    </div>
                  );
                })}
              </div>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        mb="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 4"
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          border="1px solid #FFA303"
          borderRadius="5px"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex justify-content-between px-3 mt-2 align-items-center">
            <h6 style={{ fontWeight: "bold" }}>Total Orders</h6>
          </div>

          <h3 className="blue-text px-3">{counts.totalOrders}</h3>

          <span className="mb-2 px-3">
            Over last month{" "}
            <span style={{ color: "green" }}>
              1.4% <NorthIcon fontSize="10px" />
            </span>
          </span>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          border="1px solid #FFA303"
          borderRadius="5px"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex justify-content-between px-3 mt-2 align-items-center">
            <h6 style={{ fontWeight: "bold" }}>Total Sales</h6>
          </div>

          <h3 className="blue-text px-3">₹ {counts.totalSales}</h3>

          <span className="mb-2 px-3">
            Over last month{" "}
            <span style={{ color: "red" }}>
              1.4% <SouthIcon fontSize="10px" />
            </span>
          </span>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 1"
          backgroundColor={colors.primary[400]}
          border="1px solid #FFA303"
          borderRadius="5px"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex justify-content-between px-3 mt-2 align-items-center">
            <h6 style={{ fontWeight: "bold" }}>Recent Review</h6>
          </div>

          <div className="px-3">
            <div className="d-flex">
              <img className="me-2" src={reveiw} alt="review" />
              <div>
                <h6>Chand Khanna</h6>
              </div>
            </div>
            <p>
              Facilisi at justo sodales dui ipsum tortor. Maecenas volutpat
              consectetur arcu mi....
            </p>
          </div>
        </Box>
      </Box>

      <Box
        mb="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          border="1px solid #FFA303"
          borderRadius="5px"
        >
          <div className="px-3 mt-2">
            <h6 style={{ fontWeight: "bold" }}>Daily Sold</h6>
            <span>Overall Sales</span>
          </div>

          <div className="spinner mt-5">
            <span>{counts.today}</span>
          </div>
        </Box>
        <Box
          gridColumn="span 9"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          border="1px solid #FFA303"
          borderRadius="5px"
        >
          <div className="d-flex justify-content-between px-3 mt-2 align-items-center">
            <div>
              <h6 style={{ fontWeight: "bold" }}>Recent Product Sold</h6>
              <span>Recent Product Sold</span>
            </div>
            <button
              onClick={() => navigate("/resumatic/orders")}
              className="view-all-btn"
            >
              View All
            </button>
          </div>

          <div className="px-3 mt-3">
            <div className="d-flex justify-content-between tableHeaders">
              <span>Avatar</span>
              <span>Name</span>
              <span>Product Name</span>
              <span>Date</span>
              <span>Price</span>
            </div>

            {invoices?.slice(0,3)?.map((data) => {
              return (
                <div className="tableRows align-items-center">
                  <span>
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        objectFit: "contain",
                      }}
                    >
                      <img
                        src={
                          data?.user?.profileImage
                            ? data?.user?.profileImage
                            : profile
                        }
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </span>
                  <span>{data?.user?.name}</span>
                  <span>{data?.name}</span>
                  <span>{data?.date?.split("T")[0]}</span>
                  <span>{data?.amount}</span>
                </div>
              );
            })}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
