import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import "./index.css"
import product from "../../images/product.png";
import NorthIcon from "@mui/icons-material/North";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Practice from "../../components/Practice";


const Products = () => {
  const navigate = useNavigate();
    const [active, setActive] = useState("one");
  return (
    <Box
      px="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <div className="d-flex align-items-center justify-content-center py-3 mb-4">
        <div className="d-flex">
          <div
            onClick={() => setActive("one")}
            className={`box-item ${active === "one" && "box-item-active"}`}
          >
            <span>Product</span>
          </div>
          <div
            onClick={() => setActive("two")}
            className={`box-item2 ${active === "two" && "box-item-active"}`}
          >
            <span>Best Resume / Practice</span>
          </div>
        </div>
      </div>

      {active === "one" && (
        <>
          <div className="mb-3">
            <div
              onClick={() => navigate("/resumatic/products/create")}
              className="create-product-container d-flex flex-column justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center justify-content-center top-item">
                <AddCircleOutlineRoundedIcon
                  style={{ fontSize: "40px", color: "#521986" }}
                />
              </div>
              <div className="bottom-item">Create New Product</div>
            </div>
          </div>

          <div className="create-product-container2 d-flex flex-column justify-content-between">
            <div className="top-item-img">
              <img src={product} alt="" />
            </div>
            <div className="top-item-text p-2">
              <h3>Resumatic</h3>
              <div className="live-item-text">
                <span className="live-dot-text"></span>
                <span>
                  Active Users <span className="text-bold">256000</span>
                </span>
              </div>
              <div className="live-item-text">
                <span>
                  Total Users <span className="text-bold">256000</span>
                </span>
              </div>

              <div className="live-item-text">
                <span>
                  Overall Sales this month{" "}
                  <span style={{ color: "green" }}>
                    1.4% <NorthIcon fontSize="10px" />
                  </span>
                </span>
              </div>
            </div>
            <div
              onClick={() => navigate("/resumatic/products/create")}
              className="bottom-item"
            >
              View More
            </div>
          </div>
        </>
      )}

      {active === "two" && <Practice />}
    </Box>
  );
};

export default Products;
