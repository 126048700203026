import { Box } from "@mui/material";

const Chats = () => {
  return (
    <Box p="20px">
      <div>Chat Page</div>
    </Box>
  );
};

export default Chats;
