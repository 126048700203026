import { Box } from "@mui/material";
import React from "react";
import { Modal, Nav, NavDropdown, Spinner } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { deletePlanReq, updatePlanReq } from "../apis";

const PricingTable = ({ getPlanData, planData }) => {
  const [loading3, setLoading3] = useState(false);
  const [editPlanData, setEditPlanData] = useState({
    _id: "",
    name: "",
    aicredits: "",
    price: "",
    totalResume: "",
    totalCover: "",
    isAnnual: true,
  });
  const [show2, setShow2] = useState(false);

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      setLoading3(true);
      const res = await updatePlanReq(editPlanData._id, {
        name: editPlanData.name,
        aicredits: editPlanData.aicredits,
        price: editPlanData.price,
        isAnnual: editPlanData.isAnnual,
        totalResume: editPlanData?.totalResume,
        totalCover: editPlanData?.totalCover
      });
      console.log(res);
      if (res?.data?.success) {
        setShow2(false);
        setLoading3(false);
        alert(res?.data?.message);
        getPlanData();
      }
    } catch (error) {
      setLoading3(false);
      console.log(error);
    }
  };

  const editChangeHandler = (e) => {
    setEditPlanData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

    const labelChangeHandler = (e) => {
      if (e.target.name === "annual") {
        setEditPlanData((prev) => {
          return {
            ...prev,
            isAnnual: true,
          };
        });
      } else {
        setEditPlanData((prev) => {
          return {
            ...prev,
            isAnnual: false,
          };
        });
      }
    };

  const handleDelete = async (id) => {
    const action = window.confirm("Are you sure you want to delete?");
    if (action) {
      try {
        const res = await deletePlanReq(id);
        if (res?.data?.success) {
          alert(res?.data?.message);
          getPlanData();
        } else {
          alert("Something went wrong!");
        }
      } catch (error) {
        console.log(error);
        alert("Something went wrong!");
      }
    }
  };

  return (
    <>
      <Box paddingBottom="20px">
        <div className="mt-3">
          <div className="d-flex justify-content-between tableHeaders">
            <span>S. No.</span>
            <span>Name</span>
            <span>Credits</span>
            <span>Resume</span>
            <span>Cover Letter</span>
            <span>Price</span>
            <span>Plan Type</span>
            <span>Actions</span>
          </div>

          {planData &&
            planData?.map((plan, index) => {
              return (
                <div className="tableRows d-flex align-items-center">
                  <span>{index + 1}</span>
                  <span>{plan?.name}</span>
                  <span>{plan?.aicredits}</span>
                  <span>{plan?.totalResume}</span>
                  <span>{plan?.totalCover}</span>
                  <span>{plan?.price}</span>
                  <span>{plan?.isAnnual ? "Annual" : "Monthly"}</span>
                  <span>
                    <Nav>
                      <NavDropdown
                        title={
                          <KeyboardArrowDownIcon style={{ color: "#FFA303" }} />
                        }
                        id="basic-nav-dropdown"
                      >
                        <div className="dropdown_item d-flex flex-column p-3">
                          <span
                            style={{ padding: "10px 5px", cursor: "pointer" }}
                            onClick={() => {
                              setEditPlanData({
                                _id: plan?._id,
                                name: plan?.name,
                                aicredits: plan?.aicredits,
                                price: plan?.price,
                                totalResume: plan?.totalResume,
                                totalCover: plan?.totalCover,
                                isAnnual: plan?.isAnnual,
                              });
                              setShow2(true);
                            }}
                          >
                            Edit
                          </span>
                          <span
                            onClick={() => handleDelete(plan?._id)}
                            style={{ padding: "10px 5px", cursor: "pointer" }}
                          >
                            Delete
                          </span>
                        </div>
                      </NavDropdown>
                    </Nav>
                  </span>
                </div>
              );
            })}
        </div>
      </Box>

      {/* <div className="d-flex justify-content-end">
        <Pagination
          count={1}
          variant="outlined"
          shape="rounded"
          sx={{
            ".MuiPaginationItem-rounded": {
              color: "#ee9803",
              border: "1px solid #ee9803",
            },
            ".Mui-selected": {
              background: "#ee9803 !important",
              color: "white !important",
            },
            ".MuiPaginationItem-rounded:hover": {
              background: "#ee9803",
              color: "white",
            },
            ".MuiPaginationItem-rounded:active": {
              background: "#ee9803 !important",
              color: "white !important",
            },
          }}
        />
      </div> */}

      <Modal show={show2} onHide={() => setShow2(false)} centered>
        <Modal.Body>
          <div className="p-2">
            <h3 className="text-bold mb-3">Update Plan</h3>
            <form onSubmit={handleEdit} className="w-100">
              <label htmlFor="name" className="d-flex flex-column mb-3">
                <span>Name</span>
                <input
                  type="text"
                  placeholder="Enter Plan Name"
                  className="modal-input"
                  name="name"
                  defaultValue={editPlanData.name}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              <label htmlFor="aicredits" className="d-flex flex-column mb-3">
                <span>Credits</span>
                <input
                  type="text"
                  placeholder="Enter Credits"
                  className="modal-input"
                  name="aicredits"
                  defaultValue={editPlanData.aicredits}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              <label htmlFor="price" className="d-flex flex-column mb-3">
                <span>Price</span>
                <input
                  type="text"
                  placeholder="Enter Price"
                  className="modal-input"
                  name="price"
                  defaultValue={editPlanData.price}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              <label htmlFor="totalResume" className="d-flex flex-column mb-3">
                <span>Total Resume</span>
                <input
                  type="text"
                  placeholder="Enter No of Resume"
                  className="modal-input"
                  name="totalResume"
                  defaultValue={editPlanData.totalResume}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              <label htmlFor="totalCover" className="d-flex flex-column mb-3">
                <span>Total Cover Letter</span>
                <input
                  type="text"
                  placeholder="Enter No of Cover Letter"
                  className="modal-input"
                  name="totalCover"
                  defaultValue={editPlanData.totalCover}
                  onChange={editChangeHandler}
                  required
                />
              </label>
              <label htmlFor="isAnnual" className="d-flex flex-row mb-3">
                <div className="d-flex align-items-center me-4">
                  <input
                    type="radio"
                    name="annual"
                    id="annual"
                    checked={editPlanData?.isAnnual ? true : false}
                    onChange={labelChangeHandler}
                  />
                  <label htmlFor="annual">Annual</label>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="month"
                    id="month"
                    checked={editPlanData?.isAnnual ? false : true}
                    onChange={labelChangeHandler}
                  />
                  <label htmlFor="month">Monthly</label>
                </div>
              </label>
              <div>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "none",
                    backgroundColor: "#521986",
                    color: "white",
                    outline: "none",
                    borderRadius: "5px",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  {loading3 ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  style={{
                    width: "150px",
                    height: "38px",
                    border: "1px solid #521986",
                    backgroundColor: "white",
                    color: "#521986",
                    outline: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => setShow2(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PricingTable;
