import React, { useState } from "react";
import profile from "../../images/profile.png";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import cover from "../../images/cover.png";
import "./index.css";
import profile2 from "../../images/profile2.png";


const Profile = () => {
  const [active, setActive] = useState("one");
  return (
    <Box
      p="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <div className="w-100 imgContainer mb-3">
        <img src={cover} alt="cover" />

        <div className="profileCOntainer d-flex align-items-center">
          <div className="profileImgContainer me-4">
            <img src={profile2} alt="" />
          </div>
          <div className="profile-text">
            <h4>Amit Sharma</h4>
            <h6>SDE</h6>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-3 w-50">
        <div
          onClick={() => setActive("one")}
          className={`box-container ${
            active === "one" && "box-container-active"
          } `}
        >
          <span>Profile</span>
        </div>
        <div
          onClick={() => setActive("two")}
          className={`box-container ${
            active === "two" && "box-container-active"
          } `}
        >
          <span>Password</span>
        </div>
        <div
          onClick={() => setActive("three")}
          className={`box-container ${
            active === "three" && "box-container-active"
          } `}
        >
          <span>Notification</span>
        </div>
      </div>

      {active === "one" && (
        <div className="boxContainer p-3">
          <div>
            <div className="d-flex align-items-center mb-3">
              <div class="profile-pic me-4">
                <label class="-label" for="file">
                  <span class="glyphicon glyphicon-camera"></span>
                  <span>Change Image</span>
                </label>
                <input id="file" type="file" onchange="loadFile(event)" />
                <img src={profile2} id="output" width="200" />
              </div>

              <div className="profile-text-container">
                <h5>Amit Sharma</h5>
                <span>SDE</span>
              </div>
            </div>

            <div>
              <h4 className="bolder-font">Basic Information</h4>
              <form className="d-flex flex-wrap justify-content-between">
                <label
                  className="d-flex flex-column input-container"
                  htmlFor="name"
                >
                  <span>Name</span>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="px-2"
                    placeholder="Enter Name"
                  />
                </label>
                <label
                  className="d-flex flex-column input-container"
                  htmlFor="name"
                >
                  <span>Role</span>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="px-2"
                    placeholder="Enter Role"
                  />
                </label>
                <label
                  className="d-flex flex-column input-container"
                  htmlFor="name"
                >
                  <span>Email</span>
                  <input
                    type="email"
                    id="name"
                    name="name"
                    className="px-2"
                    placeholder="Enter Email"
                  />
                </label>
                <label
                  className="d-flex flex-column input-container"
                  htmlFor="name"
                >
                  <span>Department</span>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="px-2"
                    placeholder="Enter Department"
                  />
                </label>
                <label
                  className="d-flex flex-column input-container"
                  htmlFor="name"
                >
                  <span>Phone</span>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    className="px-2"
                    placeholder="Enter Phone"
                  />
                </label>
                <label
                  className="d-flex flex-column input-container"
                  htmlFor="name"
                >
                  <span>Date of Birth</span>
                  <input type="date" id="dob" name="dob" className="px-2" />
                </label>
                <label
                  className="d-flex flex-column input-container"
                  htmlFor="name"
                >
                  <span>Address</span>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    className="px-2"
                    placeholder="Enter Address"
                  />
                </label>
                <label
                  className="d-flex flex-column input-container"
                  htmlFor="name"
                >
                  <span>Gender</span>
                  <select name="gender" id="gender" className="px-2">
                    <option value="">Select Gender</option>
                    <option value="">Male</option>
                    <option value="">Female</option>
                  </select>
                </label>

                <button className="btn-form-submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      )}
      {active === "two" && (
        <div className="boxContainer p-3 w-50">
          <div>
            <h4 className="mb-3  bolder-font">Change Password</h4>
            <form className="d-flex flex-wrap justify-content-between w-100">
              <label
                className="d-flex flex-column input-container w-100"
                htmlFor="opass"
              >
                <span>Old Password</span>
                <input
                  type="text"
                  id="opass"
                  name="opass"
                  className="px-2"
                  placeholder="Enter Old Password"
                />
              </label>
              <label
                className="d-flex flex-column input-container w-100"
                htmlFor="name"
              >
                <span>New Password</span>
                <input
                  type="text"
                  id="newpass"
                  name="newpass"
                  className="px-2"
                  placeholder="Enter New Password"
                />
              </label>
              <label
                className="d-flex flex-column input-container w-100"
                htmlFor="name"
              >
                <span>Confirm Password</span>
                <input
                  type="email"
                  id="newpassrepeat"
                  name="newpassrepeat"
                  className="px-2"
                  placeholder="Enter Confirm Password"
                />
              </label>

              <button className="btn-form-submit">Submit</button>
            </form>
          </div>
        </div>
      )}
      {active === "three" && (
        <div className="boxContainer p-3 w-50">
          <div>
            <h4 className="mb-3 bolder-font">Notification</h4>
            <div className="mb-4">
              <h6 className="bolder-font">Activity Notifications</h6>
              <div className="d-flex justify-content-between align-items-center">
                <p>Someone assigns me to a task</p>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Someone mentions me in a conversation</p>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Someone adds me to a project</p>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Activity on a project I am a member of</p>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div>
              <h6 className="bolder-font">Service Notifications</h6>
              <div className="d-flex justify-content-between align-items-center">
                <p>Monthly newsletter</p>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Major feature enhancements</p>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Minor updates and bug fixes</p>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default Profile;
