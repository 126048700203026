import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const loginHandler = async() => {
    try {
      setLoader(true)
      const data = {
        email: username,
        password: password
      }
      const res = await axios.post(
        "https://api.viosa.in/admin/auth/login",
        data
      );
      if(res?.data?.token) {
        localStorage.setItem("resumatic_token", res?.data?.token);
        window.location.replace("/resumatic/dashboard");
      }else {
        alert("Something went wrong!")
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      alert("Something went wrong!")
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(username && password) {
      loginHandler();
    }else {
      alert("Username or Password Incorrect!!");
    }
  };

  const autoLoginHandler = () => {
    const token = searchParams.get("token");
    if(token || token?.length > 0) {
      localStorage.setItem("resumatic_token", token);
      window.location.replace("/resumatic/dashboard");
    }
  }

  useEffect(() => {
    autoLoginHandler();
  }, []);

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100 text-black">
        <MDBCol col="12">
          <MDBCard
            className="bg-white my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <MDBCardBody className="p-5 w-100 d-flex flex-column">
              <h2 className="fw-bold mb-2 text-center mb-5">Admin Login</h2>

              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Email"
                id="formControlLg"
                type="text"
                size="lg"
                onChange={(e) => setUsername(e.target.value)}
              />
              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Password"
                id="formControlLg"
                type={passwordShown ? "text" : "password"}
                size="lg"
                onChange={(e) => setPassword(e.target.value)}
                className="passwordInput"
              />

              <MDBCheckbox
                name="flexCheck"
                id="flexCheckDefault"
                className="mb-4"
                label="See password"
                onClick={() => togglePasswordVisiblity()}
              />

              <MDBBtn size="lg" onClick={handleSubmit}>
                {loader ? "Loading..." : "Login"}
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Login;
