import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import "./index.css";
import { useState } from "react";
import Resume from "../../components/Resume";
import Cover from "../../components/Cover";

const CreateProduct = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("one");

  return (
    <Box
      px="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <div className="d-flex align-items-center justify-content-center py-3 mb-4">
        <div className="d-flex">
          <div
            onClick={() => setActive("one")}
            className={`box-item ${active === "one" && "box-item-active"}`}
          >
            <AttachFileRoundedIcon />
            <span>Resume</span>
          </div>
          <div
            onClick={() => setActive("two")}
            className={`box-item2 ${active === "two" && "box-item-active"}`}
          >
            <DescriptionRoundedIcon />
            <span>Cover Letter</span>
          </div>
        </div>
      </div>
      

      {
        active === "one" && <Resume />
      }
      {
        active === "two" && <Cover />
      }
    </Box>
  );
};

export default CreateProduct;
