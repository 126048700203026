import React from 'react'
import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div style={{width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Spinner animation="border"/>
    </div>
  );
}

export default Loader