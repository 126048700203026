import React from "react";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import resume from "../images/resume.png";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";

  const modules = {
    toolbar: [
      [{ header: [7] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [{ color: ["red", "#fff"] }],
      [{ background: ["red", "#785412", "white", "#121212"] }],
    ],
  };

    const formats = [
      "header",
      "bold",
      "vertical",
      "video",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "link",
      "color",
      "image",
      "background",
      "align",
      "size",
      "font",
    ];

const Resume = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [file, setFile] = useState(null);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const handleChange = (file) => {
    setFile(file);
  };
  const fileTypes = ["JPG", "PNG", "GIF"];

  const checkChanger = (e, type) => {
    if (type === "one") {
        setChecked1(e.target.checked);
        setChecked2(false);
    }
    if (type === "two") {
        setChecked2(e.target.checked);
        setChecked1(false);
    }
  };

  console.log(checked1, checked2)
  return (
    <div>
      <div className="d-flex justify-content-between">
        <label htmlFor="name" className="d-flex flex-column mb-3 col-5">
          <span>Name</span>
          <input type="text" placeholder="Enter Name" className="modal-input" />
        </label>
        <label htmlFor="name" className="d-flex flex-column mb-3 col-5">
          <span>Price</span>
          <select name="price" id="price" className="modal-input">
            <option value="">Select Price</option>
            <option value="">Free</option>
            <option value="">Premium</option>
          </select>
        </label>
        <label
          htmlFor="name"
          className="d-flex flex-column justify-content-end mb-3 col-1"
        >
          <span>Color</span>
          <input
            type="color"
            placeholder="Enter Price"
            className="modal-input"
            defaultValue="#FFFFFF"
          />
        </label>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-5">
        {/* <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
        /> */}

        <ReactQuill
          theme="snow"
          width="100%"
          modules={modules}
          style={{
            width: "100%",
            height: "200px",
            fontSize: "14px",
          }}
          id="description_id"
          formats={formats}
          // defaultValue={descption}
          // onChange={(content, delta, source, editor) => {
          //   setDescption(editor.getHTML());
          // }}
        />
      </div>

      <div>
        <button
          style={{
            width: "150px",
            height: "38px",
            border: "none",
            backgroundColor: "#521986",
            color: "white",
            outline: "none",
            borderRadius: "5px",
            marginRight: "30px",
          }}
        >
          Submit
        </button>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-5 color-selector-resume">
        <h4>Your Uploaded Resume</h4>
        <div className="d-flex justify-content-between align-items-center color-selector-item">
          <h6>Select Color</h6>
          <span className="color color1"></span>
          <span className="color color2"></span>
          <span className="color color3"></span>
          <span className="color color4"></span>
          <span className="color color5"></span>
        </div>
      </div>

      <div className="d-flex justify-content-between flex-wrap mt-3">
        <div onClick={handleShow} className="resume-container">
          <div className="resume-image-container">
            <img src={resume} alt="" />
          </div>
          <div className="d-flex justify-content-between px-3 my-3 align-items-center">
            <span className="resume-span1">Bubble</span>
            <span className="resume-span2">Premium</span>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div>
            <h6 className="modal-font mb-2">Resume Uploaded</h6>
            <div className="resume-image-container-modal mb-4">
              <img src={resume} alt="" />
            </div>
            <form className="d-flex flex-column">
              <label htmlFor="name" className="w-100 mb-4">
                <span>Name of Resume</span>
                <input
                  className="w-100 modal-input"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter Name of Resume"
                />
              </label>
              <div className="d-flex mb-4 flex-column">
                <span>Pricing</span>
                <div className="d-flex">
                  <label className="w-100 me-3" htmlFor="free">
                    <div className="modal-input d-flex align-items-center justify-content-center">
                      <input
                        className="me-3 checkbox"
                        type="checkbox"
                        style={{ borderRadius: "50%" }}
                        name="free"
                        id="free"
                        defaultChecked={checked1 === true ? true : false}
                        placeholder="Enter Name of Resume"
                        onChange={(e) => checkChanger(e, "one")}
                      />
                      <span>Free</span>
                    </div>
                  </label>
                  <label className="w-100" htmlFor="premium">
                    <div className="modal-input d-flex align-items-center justify-content-center">
                      <input
                        className="me-3 checkbox"
                        style={{ borderRadius: "50%" }}
                        type="checkbox"
                        name="premium"
                        defaultChecked={checked2 === true ? true : false}
                        id="premium"
                        placeholder="Enter Name of Resume"
                        onChange={(e) => checkChanger(e, "two")}
                      />
                      <span>Premium</span>
                    </div>
                  </label>
                </div>
              </div>
            </form>

            <div className="d-flex justify-content-between flex-column color-selector-item mb-4">
              <h6 className="mb-2">Select Color</h6>
              <div className="d-flex justify-content-between align-items-center modal-color-selector">
                <span className="color color1"></span>
                <span className="color color2"></span>
                <span className="color color3"></span>
                <span className="color color4"></span>
                <span className="color color3"></span>
                <span className="color color5"></span>
                <span className="color color5"></span>
                <span className="color color5"></span>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <button className="discard-btn" onClick={handleClose}>
                Discard
              </button>
              <button className="upload-btn">Upload</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Resume;
