import { Box } from "@mui/material";
import React from "react";
import "./index.css";
import { useEffect } from "react";
import { getPracticeByIdReq } from "../../apis";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactPlayer from "react-player";

const BestPractice = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log(id);

  const getPracticeByIdHandler = async () => {
    try {
      setLoading(true);
      const res = await getPracticeByIdReq(id);
      setData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getPracticeByIdHandler();
  }, []);

  console.log(data?.video)
  return (
    <Box
      px="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      {loading ? (
        <div
          style={{
            height: "80vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" size="md" />
        </div>
      ) : (
        <>
          <div className="mb-3">
            <h4 className="text-bold">{data?.title}</h4>
            <h5>{data?.subtitle}</h5>
          </div>
          <ReactPlayer
            loop={true}
            playing={true}
            controls
            style={{ width: "100%", borderRadius: "5px", overflow: "hidden" }}
            width="100%"
            url={data?.video}
            volume={1}
            muted={false}
          />

          <div className="mt-4">
            <p style={{ fontSize: "18px" }}>{data?.description}</p>
          </div>
        </>
      )}
    </Box>
  );
};

export default BestPractice;
