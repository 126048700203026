import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import logo from "../../images/logo.svg";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import EmailIcon from "@mui/icons-material/Email";
import { useCookies } from "react-cookie";
import WorkIcon from "@mui/icons-material/Work";
import profile from "../../images/profile.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DvrIcon from "@mui/icons-material/Dvr";
import InventoryIcon from "@mui/icons-material/Inventory";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: "white",
      }}
      onClick={() => {
        setSelected(title);
        if (title === "Logout") {
          localStorage.clear();
          window.location.replace("/");
        }
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      {title !== "Logout" && <Link to={to} />}
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
          marginBottom: "1px",
        },
        "& .pro-inner-item:hover": {
          color: "#521986 !important",
          background: "white",
          borderRadius: "6px",
        },
        "& .pro-menu-item.active": {
          color: "#521986 !important",
          background: "white",
          borderRadius: "6px",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              height: "70px",
              color: "white",
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <img src={logo} alt="logo" />
              </Box>
            )}
          </MenuItem>

          {/* {!isCollapsed && (
            <Box padding="10px">
              <Box
                textAlign="center"
                display="flex"
                padding="10px 20px"
                backgroundColor="white"
                borderRadius="6px"
              >
                <div className="me-3">
                  <img src={profile} alt="profile" />
                </div>
                <div className="d-flex flex-column justify-content-left">
                  <Typography color="#521986" variant="h5" textAlign="left">
                    Admin
                  </Typography>
                  <Typography color="#521986" variant="p" textAlign="left">
                    Content Creator
                  </Typography>
                </div>
              </Box>
            </Box>
          )} */}

          <Box paddingLeft={isCollapsed ? undefined : "0%"} padding="10px">
            <Item
              title="Dashboard"
              to="/resumatic/dashboard"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Orders"
              to="/resumatic/orders"
              icon={<DvrIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Products"
              to="/resumatic/products"
              icon={<InventoryIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Customer"
              to="/resumatic/customer"
              icon={<PeopleAltRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Invoice"
              to="/resumatic/invoice"
              icon={<ReceiptRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Chats"
              to="/resumatic/chats"
              icon={<ChatRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Emails"
              to="/resumatic/emails"
              icon={<EmailRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Profile"
              to="/resumatic/profile"
              icon={<AccountCircleRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="User"
              to="/resumatic/users"
              icon={<SupervisedUserCircleRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Pricing Table"
              to="/resumatic/pricing-table"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Logout"
              to="/"
              icon={<LogoutIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="Profile Form"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <MenuItem
              style={{
                color: "white",
              }}
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={<SettingsOverscanIcon />}
            >
              <Box className="d-flex">
                {!isCollapsed && <Typography>Close Sidebar</Typography>}
              </Box>
            </MenuItem>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
