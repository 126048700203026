import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./index.css";
import profile from "../../images/profile.png";
import Pagination from "@mui/material/Pagination";
import { Nav, NavDropdown } from "react-bootstrap";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { getInvoiceReq } from "../../apis";
import { useEffect } from "react";
import Loader from "../../ui/Loader";
import exportFromJSON from "export-from-json";
const Orders = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [search, setSearch] = useState("");

  const getInvoiceHandler = async (pageNo, searchItem) => {
    try {
      setLoading(true);
      const res = await getInvoiceReq(pageNo, searchItem);
      if (res?.data?.success) {
        setInvoices(res?.data?.data?.paym);
        setPage(res?.data?.data?.page);
        setPages(res?.data?.data?.pages);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getInvoiceHandler(page, search);
  }, [page, search]);

    const downloadUsers = () => {
      const fileName = `Orders_${new Date(Date.now())}`;
      const exportType = exportFromJSON.types.csv;
      const userWithouPassword = invoices.map((user) => {
        return {
          ID: user?._id,
          Date: user?.date,
          Time: user?.date?.split("T")[1]?.split(".")[0],
          Name: user?.name,
          Email: user.email,
          Phone: user.phoneNumber,
          Status: user?.status ? user?.status : "",
          RazorPayID: user?.transactionId,
          OrderID: user?.orderId,
          Currency: "INR",
          Amount: user?.user?.subscription?.amount,
          CourseID: user?.user?.course[0]?._id,
        };
      });
      const data = userWithouPassword;
      exportFromJSON({ data, fileName, exportType });
    };

  return (
    <Box
      p="20px"
      paddingBottom="20px"
      height="calc(100vh - 70px)"
      overflow="auto"
    >
      <Box className="d-flex align-items-center justify-content-between mb-5">
        <div>
          <h6 className="heading1">All Orders</h6>
          <span className="heading">Overall Product Ordered</span>
        </div>
        <div className="search-Input">
          <input
            type="text"
            placeholder="Search by User Name or Plan Name"
            onChange={searchHandler}
          />
          <SearchIcon className="search-icon" />
        </div>
        <button
          onClick={downloadUsers}
          style={{
            marginLeft: "20px",
            width: "200px",
            height: "40px",
            border: "none",
            backgroundColor: "#521986",
            color: "white",
            outline: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Export
        </button>
      </Box>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Box>
            <div className="mt-3">
              <div className="d-flex justify-content-between tableHeaders">
                <span>Avatar</span>
                <span>User</span>
                <span>User Email</span>
                <span>Invoice</span>
                <span>Plan Name</span>
                <span>Plan Type</span>
                <span>Price</span>
                <span>Date</span>
                <span>Expiry Date</span>
                {/* <span>Actions</span> */}
              </div>

              {invoices.map((data) => {
                return (
                  <div className="tableRows d-flex align-items-center">
                    <span>
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          objectFit: "contain",
                        }}
                      >
                        <img
                          src={
                            data?.profileImage ? data?.profileImage : profile
                          }
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    </span>
                    <span>{data?.name}</span>
                    <span>{data?.user?.email}</span>
                    <span style={{ color: "#ffba42" }}>Invoice-222</span>
                    <span>{data?.planName}</span>
                    <span>{data?.planType.toUpperCase()}</span>
                    <span>{data?.amount}</span>
                    <span>{data?.date?.split("T")[0]}</span>
                    <span>{data?.lastDate?.split("T")[0]}</span>
                    {/* <span>
                            <Nav>
                              <NavDropdown
                                title={
                                  <KeyboardArrowDownIcon
                                    style={{ color: "#FFA303" }}
                                  />
                                }
                                id="basic-nav-dropdown"
                              >
                                <div className="dropdown_item d-flex flex-column p-3">
                                  <span
                                    style={{
                                      padding: "10px 5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Edit
                                  </span>
                                  <span
                                    style={{
                                      padding: "10px 5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Delete
                                  </span>
                                  <span
                                    style={{
                                      padding: "10px 5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View Profile
                                  </span>
                                </div>
                              </NavDropdown>
                            </Nav>
                          </span> */}
                  </div>
                );
              })}
            </div>
          </Box>

          <div className="d-flex justify-content-end">
            <Pagination
              count={1}
              variant="outlined"
              shape="rounded"
              sx={{
                ".MuiPaginationItem-rounded": {
                  color: "#ee9803",
                  border: "1px solid #ee9803",
                },
                ".Mui-selected": {
                  background: "#ee9803 !important",
                  color: "white !important",
                },
                ".MuiPaginationItem-rounded:hover": {
                  background: "#ee9803",
                  color: "white",
                },
                ".MuiPaginationItem-rounded:active": {
                  background: "#ee9803 !important",
                  color: "white !important",
                },
              }}
            />
          </div>
        </>
      )}
    </Box>
  );
};

export default Orders;
